<template>
  <div
    id="history-container"
    class="container"
  >
    <filter-component @change="filterChange" />
    <div class="page-container mt-2">
      <b-row class="mb-2 d-flex justify-content-end">
        <b-col
          cols="6"
        />
        <b-col
          lg="6"
          md="12"
          sm="12"
          class="justify-content-end"
        >
          <b-row>
            <b-col
              lg="4"
              sm="6"
            >
              <b-button
                class="button-icon w-100 mb-1"
                variant="primary"
                @click="exportData"
              >
                <span class="icon-excel pr-25"></span>
                Xuất excel
              </b-button>
            </b-col>
            <b-col
              lg="4"
              sm="6"
            >
              <search
                class="w-100 mb-1"
                :value="urlQuery.query"
                @change="(val) => { urlQuery.query = val
                                    fetchData()}"
              />
            </b-col>
            <b-col
              lg="4"
              sm="6"
            >
              <v-select
                v-model="urlQuery.timeDes"
                class="sort-date w-100 mb-1"
                placeholder="Sắp xêp"
                :reduce="value => value.value"
                :options="sorts"
                label="name"
                @input="fetchData()"
              >
                <template v-slot:no-options="{ searching }">
                  <template v-if="searching">
                    Không có kết quả.
                  </template>
                  <em
                    v-else
                  >Không có dữ liệu</em>
                </template>
              </v-select>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <div
        id="divContent"
        ref="timlineContainer"
        class="timeline-container"
      >
        <app-timeline>
          <app-timeline-item
            v-for="item in historyData"
            :key="item.id"
            :variant="item.eventType==='Access' ? 'warning' :
              item.eventType==='Add' ? 'success':
              item.eventType==='Update' ? 'info' : 'danger'"
          >
            <b-media no-body>
              <b-media-aside class="mr-50">
                <b-avatar
                  :variant="item.eventType==='Access' ? 'warning' :
                    item.eventType==='Add' ? 'success':
                    item.eventType==='Update' ? 'info' : 'danger'"
                  text="N"
                  :src="item.avatar ? $serverfile + item.avatar : null"
                  size="38"
                />
              </b-media-aside>
              <b-media-body class="my-auto">
                <div class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1">
                  <span><a
                    @click="()=> {
                      urlQuery.query = item.sourceName
                      fetchData()}"
                  ><strong class="source-name">{{ item.sourceName }} ({{ item.userName }})</strong></a> {{ item.message }} <a
                    @click="()=> {
                      urlQuery.query = item.targetName
                      fetchData()}"
                  ><strong class="target-name">{{ item.targetName }}</strong></a></span>
                  <small class="timeline-item-time text-nowrap text-muted ml-1">{{ item.createDateString }}</small>
                </div>
                <div class="mb-0">
                  <b-badge
                    :variant="(item.eventType==='Access' ? 'warning' :
                      item.eventType==='Add' ? 'success':
                      item.eventType==='Update' ? 'info' : 'danger')"
                  >
                    {{ item.actionName }}
                  </b-badge>
                </div>
              </b-media-body>
            </b-media>
          </app-timeline-item>
        </app-timeline>
      </div>
      <div class="loading">
        <b-spinner
          v-if="loading"
          variant="info"
          class="align-seft-center"
        />
      </div>
    </div>
  </div>
</template>

<script>

import {
  BImg, BMedia, BMediaBody, BMediaAside, BAvatar, BBadge, BButton, BRow, BCol, BSpinner,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import VSelect from 'vue-select'
import $ from 'jquery'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../constants/ConstantsApi'
import FilterComponent from '../components/Filter.vue'
import Search from '@/components/search/Search.vue'
import ButtonComponent from '@/components/button/ButtonComponent.vue'

export default {
  components: {
    BMedia,
    BRow,
    BCol,
    BMediaBody,
    BMediaAside,
    BAvatar,
    AppTimeline,
    AppTimelineItem,
    BBadge,
    FilterComponent,
    BButton,
    Search,
    VSelect,
    BSpinner,
  },

  data() {
    return {
      urlQuery: {
        startIndex: 1,
        size: 20,
        startTime: null,
        endTime: null,
        sourceId: null,
        targetId: null,
        messageType: null,
        query: '',
        isAll: false,
        types: null,
        timeDes: true,
      },
      historyData: [],
      sorts: [
        { name: 'Cũ nhất', value: false },
        { name: 'Gần đây', value: true },
      ],
      loading: false,
    }
  },
  mounted() {
    $('#divContent').on('scroll', () => {
      const container = $('#divContent')
      if (container.scrollTop() + container.innerHeight() >= container[0].scrollHeight - 5) {
        this.fetchData(true)
      }
    })
  },

  created() {
    this.fetchData()
  },

  methods: {
    async fetchData(isAddData) {
      this.loading = true
      this.urlQuery.startIndex = isAddData ? this.urlQuery.startIndex + 20 : 0
      const { data } = await axiosApiInstance.get(ConstantsApi.HISTORY_GET_PAGING, { params: this.urlQuery })
      if (isAddData) {
        this.historyData = this.historyData.concat(data)
      } else { this.historyData = data }
      this.loading = false
    },
    async exportData() {
      const count = this.urlQuery.size
      const start = this.urlQuery.startIndex
      this.urlQuery.size = 10000
      this.urlQuery.startIndex = 0
      axiosApiInstance({
        url: ConstantsApi.HISTORY_EXPORT,
        method: 'GET',
        responseType: 'blob', // important
        params: this.urlQuery,
      }).then(response => {
        this.urlQuery.size = count
        this.urlQuery.startIndex = start
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'LichSu.xlsx') // or any other extension
        document.body.appendChild(link)
        link.click()
      })
    },
    filterChange(val) {
      this.urlQuery = { ...this.urlQuery, ...val }
      this.fetchData()
    },
  },
}
</script>

<style lang="scss">
#history-container {
  .sort-date {
    width: 220px;
  }
  .source-name {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 550;
    font-size: 14px;
    line-height: 23px;
  }
  .target-name {
    font-weight: 550;
  }
  .timeline-container {
    overflow-y: auto;
    height: 60vh;
    margin: -1rem;
    padding: 1rem;
  }
  .loading {
    text-align: center;
  }
}
</style>
