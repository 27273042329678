<template>
  <div
    id="filter-custom"
    class="page-container"
  >
    <h3 class="header">
      Bộ lọc
    </h3>
    <b-row>
      <b-col
        md="12"
        xl="8"
      >
        <b-form-group>
          <!-- <label
            for="basicInput"
          >Thao tác</label> -->
          <b-form-checkbox-group
            v-model="query.types"
            class="mt-1"
            @change="$emit('change', query)"
          >
            <b-form-checkbox value="Access">
              Truy cập hệ thống
            </b-form-checkbox>
            <b-form-checkbox value="Add">
              Thêm mới dữ liệu
            </b-form-checkbox>
            <b-form-checkbox value="Update">
              Cập nhật dữ liệu
            </b-form-checkbox>
            <b-form-checkbox value="Remove">
              Xóa bỏ dữ liệu
            </b-form-checkbox>
          </b-form-checkbox-group>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        md="4"
        xl="3"
      >
        <b-form-group>
          <label
            for="basicInput"
          >Từ ngày</label>
          <b-form-datepicker
            v-model="query.startTime"
            placeholder="Chọn ngày"
            locale="vi-Vi"
            @input="$emit('change', query)"
          />
        </b-form-group>
      </b-col>
      <b-col
        md="4"
        xl="3"
      >
        <b-form-group>
          <label
            for="basicInput"
          >Đến ngày</label>
          <b-form-datepicker
            v-model="query.endTime"
            placeholder="Chọn ngày"
            locale="vi-Vi"
            @input="$emit('change', query)"
          />
        </b-form-group>
      </b-col>
      <b-col
        md="6"
        xl=""
        class="all-unit"
      >
        <b-form-checkbox
          v-model="query.isAll"
          switch
          @change="$emit('change', query)"
        >
          Tất cả các đơn vị thuộc hệ thống
        </b-form-checkbox>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BFormDatepicker, BFormCheckboxGroup,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormCheckbox,
    BFormDatepicker,
    BFormCheckboxGroup,
  },

  data() {
    return {
      organizationStructureId: '',
      query: {
        types: [],
        startTime: null,
        endTime: null,
        isAll: false,
      },
    }
  },
}
</script>

<style lang="scss">
#filter-custom{
  .header{
    font-size: 18px;
    color: #181F28;
  }
  .all-unit {
      margin: auto;
  }
}
</style>
